import React from 'react';

const BASE_COLOR = '#707070'; // space gray
const KEYBOARD_COLOR = '#111';
const SCREEN_COLOR = '#111';

const Computer = ({ width, height, size, props }) => (
  <svg
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    width={width || size}
    height={height || size}
    {...props}
  >
    <linearGradient
      id="computerA"
      x1="-93.047"
      x2="-93.047"
      y1="-73.413"
      y2="-17.291"
      gradientUnits="userSpaceOnUse"
    >
      <stop stopColor="#eee" offset="0.005" />
      <stop stopColor="#EAEAEA" offset="0.282" />
      <stop stopColor="#ddd" offset="0.577" />
      <stop stopColor="#C8C8C8" offset="0.879" />
      <stop stopColor="#BDBDBD" offset="1" />
    </linearGradient>
    <path
      d="M-42.13-44.72c-.91-4.33-4.3-20.11-5.87-27.41-.47-2.21-2.11-3.82-3.99-3.81-5.36 0-15.39-.05-16.6-.05h-44.08c-.61 0-2.56.06-4.11 1.79-4.7 5.27-12.54 20.52-13.76 22.07-.08.1-6.27 2.31-9.09 4-1.12.67-1.74 2.13-1.9 3.63l-2.46 20.75c-.64 4.1 1.7 5.76 5.37 5.76h91.51c2.8 0 5.11-.68 5.11-5.69v-19.79c0-.43-.05-.85-.13-1.25z"
      fill="url(#computerA)"
    />
    <path
      d="M-51.98-73c.36 0 .87.59 1.05 1.44 1.46 6.78 4.95 23.06 5.86 27.43.04.2.07.44.07.66v19.79c0 2.81-.49 2.69-2.11 2.69h-91.51c-1.3 0-2.02-.23-2.25-.5s-.3-.92-.16-1.77c.01-.04.01-.06.02-.09l2.47-20.83c.08-.8.36-1.28.46-1.36 1.87-1.12 5.83-2.66 7.51-3.31 1.46-.57 1.86-.72 2.39-1.39.5-.63 1.28-2 2.99-5.03 2.85-5.03 7.61-13.53 10.65-16.93.57-.64 1.22-.79 1.87-.79h60.69-.02m.02-3.01s-.01 0 0 0h-60.7c-.63 0-2.55.08-4.08 1.79-4.7 5.27-12.54 20.52-13.76 22.07-.08.1-6.27 2.31-9.09 4-1.12.67-1.74 2.13-1.9 3.63l-2.46 20.75c-.64 4.1 1.7 5.76 5.37 5.76h91.51c2.8 0 5.11-.68 5.11-5.69v-19.79c0-.42-.05-.84-.13-1.24-.91-4.33-4.3-20.17-5.87-27.47-.49-2.21-2.13-3.81-4-3.81z"
      fill="#424242"
      opacity="0.2"
    />
    <linearGradient
      id="computerC"
      x1="46.43"
      x2="46.43"
      y1="-117.5"
      y2="-61.374"
      gradientUnits="userSpaceOnUse"
    >
      <stop stopColor="#616161" offset="0.011" />
      <stop stopColor="#5D5D5D" offset="0.25" />
      <stop stopColor="#505050" offset="0.504" />
      <stop stopColor="#3B3B3B" offset="0.766" />
      <stop stopColor="#212121" offset="1" />
    </linearGradient>
    <path
      d="M97.35-88.93c-.91-4.33-4.3-20.11-5.86-27.41-.47-2.21-2.11-3.75-3.99-3.75-5.36 0-15.39.01-16.6.01H26.82c-.61 0-2.56-.01-4.11 1.73C18-113.07 10.16-97.86 8.95-96.31c-.08.1-6.27 2.3-9.09 3.99-1.12.67-1.74 2.12-1.9 3.62L-4.5-67.9c-.64 4.1 1.7 5.82 5.37 5.82h91.51c2.8 0 5.1-.8 5.1-5.81v-19.79c-.01-.43-.05-.84-.13-1.25z"
      fill="url(#computerC)"
    />
    <path
      d="M87.5-117.08c.36 0 .87.53 1.05 1.38 1.46 6.78 4.94 23.03 5.86 27.4.04.2.06.41.06.63v19.79c0 2.81-.49 2.81-2.1 2.81H.86c-1.3 0-2.02-.29-2.25-.56s-.3-.95-.16-1.8l.02-.1L.94-88.37c.08-.8.36-1.28.46-1.36 1.87-1.12 5.83-2.66 7.51-3.31 1.46-.57 1.86-.72 2.39-1.39.5-.63 1.28-2 2.99-5.03 2.85-5.03 7.61-13.46 10.65-16.87.57-.64 1.22-.72 1.87-.72h44.08c.75 0 4.89 0 9.21-.01l7.4-.02m0-3c-5.36 0-15.39.01-16.6.01H26.8c-.63 0-2.55.01-4.08 1.73C18-113.07 10.16-97.86 8.95-96.31c-.08.1-6.27 2.3-9.09 3.99-1.12.67-1.74 2.12-1.9 3.62L-4.5-67.9c-.64 4.1 1.7 5.82 5.37 5.82h91.51c2.8 0 5.1-.8 5.1-5.81v-19.79c0-.42-.04-.84-.13-1.24-.91-4.33-4.3-20.11-5.86-27.41-.48-2.22-2.12-3.75-3.99-3.75z"
      fill="#eee"
      opacity="0.2"
    />
    <path fill={BASE_COLOR} d="M112.59 85.08H15.18L4 104.12h119.88z" />
    <path
      d="M19.08 82.42l89.61.01 11.68 19.7H7.5l11.58-19.71m-1.15-2L4 104.12h119.88l-14.05-23.7h-91.9z"
      fill="#424242"
      opacity="0.2"
    />
    <path
      d="M26.79 88.03h-6.44l.87-1.68h6.46zM36.87 88.03h-6.44l.8-1.68h6.61zM46.46 88.03h-6.44l.92-1.68h6.45zM56 88.03h-6.44l1.04-1.68h6.03zM65.4 88.03h-6.44l.51-1.68h5.41zM75 88.03h-6.45l-.71-1.68h6.1zM84.84 88.03H78.4l-1.22-1.68h6.44zM94.33 88.03h-6.44l-1.15-1.68h6.45zM107.23 88.03h-9.67l-1.23-1.68h9.95zM102.3 90.54h6.44l-1.14-1.68h-6.54zM91.77 90.54h6.44l-1.23-1.68h-6.44zM82.16 90.54h6.44l-1.09-1.68h-6.43zM71.83 90.54h6.44l-1.15-1.68h-6.07zM62.61 90.54h6.44l-.71-1.68h-5.43zM53.39 90.54h6.44l.39-1.68h-6.24zM43.3 90.54h6.44l.78-1.68h-6.18zM32.08 90.54h6.44l1-1.68h-6.45zM18.94 90.54h9.68l.95-1.68h-9.71zM79.95 93.05h6.45l-1.12-1.68h-6.44zM69.69 93.05h6.44l-.94-1.68h-6.04zM57.59 93.05h6.44l-.18-1.68h-5.76zM46.07 93.05h6.45l.57-1.68h-6.18zM33.94 93.05h6.45l1.02-1.68h-6.45zM17.69 93.05h12.85l1.06-1.68H18.63zM90.83 93.08h19.3l-1.15-1.74H89.69zM105.49 95.56h6.44l-1.3-1.68h-6.44zM93.46 95.56h6.45l-1.26-1.68h-6.44zM42.13 95.56h43.75l-1.01-1.68H43.12zM28.39 95.56h6.44l.79-1.68h-6.3zM16.08 95.56h6.45l.76-1.68H17zM41.44 99.99h44.81l-1.92-3.48H43.3z"
      fill={KEYBOARD_COLOR}
    />
    <path
      d="M121.74 108.05H6.14c-1.18 0-2.14-.96-2.14-2.14v-1.79h119.88v1.79c0 1.19-.96 2.14-2.14 2.14z"
      fill="#9E9E9E"
    />
    <path
      d="M122.88 105.12v.79c0 .63-.51 1.14-1.14 1.14H6.14c-.63 0-1.14-.51-1.14-1.14v-.79h117.88m1-1H4v1.79c0 1.18.96 2.14 2.14 2.14h115.6c1.18 0 2.14-.96 2.14-2.14v-1.79z"
      fill="#424242"
      opacity="0.2"
    />
    <path
      d="M112.59 85.08H15.18V22.79c0-1.57 1.27-2.84 2.84-2.84h91.72c1.57 0 2.84 1.27 2.84 2.84v62.29z"
      fill={BASE_COLOR}
    />
    <path
      d="M109.74 21.95c.47 0 .84.38.84.84v60.29h-93.4V22.79c0-.47.38-.84.84-.84h91.72m0-2H18.02c-1.57 0-2.84 1.27-2.84 2.84v62.29h97.41V22.79c0-1.57-1.28-2.84-2.85-2.84z"
      fill="#424242"
      opacity="0.2"
    />
    <path
      className="computerScreen"
      fill={SCREEN_COLOR}
      d="M24.02 27.23h79.66v49.9H24.02z"
    />
  </svg>
);

export default Computer;
