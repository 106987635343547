import React from 'react';

const HandWave = ({ width, height, size, props }) => (
  <svg
    viewBox="0 0 128 128"
    width={width || size}
    height={height || size}
    {...props}
  >
    <radialGradient
      id="handwaveA"
      cx={-781.31}
      cy={-556.81}
      r={89.837}
      gradientTransform="rotate(-45.001 348.956 -1254.887)"
      gradientUnits="userSpaceOnUse"
    >
      <stop stopColor="#FFCA28" offset={0.353} />
      <stop stopColor="#FFB300" offset={0.872} />
    </radialGradient>
    <path
      d="M78.8 117c-5.45 0-10.73-2.01-15.71-6-3.95-3.17-44.19-42.4-44.6-42.79-1.62-1.62-2.64-3.3-2.92-4.84-.29-1.6.2-3 1.5-4.3 1.21-1.21 2.69-1.85 4.28-1.85 1.94 0 3.93.92 5.59 2.59l16.63 15.98c.29.28.67.42 1.04.42a1.494 1.494 0 0 0 1.07-2.54L19.13 46.25c-2.66-2.66-3.91-6.73-.75-9.89 1.21-1.21 2.69-1.85 4.28-1.85 1.94 0 3.93.92 5.59 2.59l27.16 26.48c.29.28.67.43 1.05.43s.77-.15 1.06-.44c.58-.58.59-1.52.01-2.11L24.91 28.02c-1.51-1.51-2.42-3.32-2.58-5.08-.15-1.79.48-3.45 1.83-4.8 1.21-1.21 2.69-1.85 4.28-1.85 1.94 0 3.93.92 5.59 2.58L67.3 51.31c.29.28.67.43 1.05.43s.77-.15 1.06-.44c.58-.58.59-1.52.01-2.11L45.26 24.36c-1.52-1.52-2.43-3.32-2.58-5.08-.15-1.79.48-3.45 1.83-4.8 1.21-1.21 2.69-1.85 4.28-1.85 1.94 0 3.93.92 5.59 2.59 8.86 8.7 31.99 31.45 32.77 32.29.62.75 1.33.92 1.8.92 1.74 0 2.66-2.03 2.81-4.04.17-2.34-.45-5.13-1.11-8.08-.82-3.68-1.67-7.49-.73-9.72 1.85-4.38 6.13-7.92 8.43-7.92.23 0 .43.04.6.12 4.14 1.94 3.99 4.43 3.19 10.38l-.15 1.15c-.77 5.92 2.32 13.35 5.04 19.91 1.11 2.67 2.16 5.2 2.89 7.46.44 1.37 1.11 2.83 1.89 4.52 4.15 9.08 10.43 22.79-9.91 43.13C94.13 113.08 86.36 117 78.8 117z"
      fill="url(#handwaveA)"
    />
    <path
      d="M48.79 14.12c1.52 0 3.17.78 4.55 2.16C65.9 28.63 84.85 47.26 86.03 48.5c1.07 1.23 2.28 1.42 2.92 1.42 2.34 0 4.07-2.18 4.3-5.44.18-2.56-.46-5.45-1.14-8.51-.73-3.29-1.57-7.02-.81-8.81 1.7-4.02 5.61-6.97 7.07-7 2.99 1.42 3.09 2.76 2.28 8.8l-.15 1.15c-.82 6.32 2.35 13.95 5.14 20.67 1.1 2.64 2.13 5.14 2.85 7.34.47 1.45 1.19 3.02 1.95 4.68 2 4.38 4.5 9.83 4.1 16.51-.48 8.04-4.96 16.2-13.71 24.94-7.47 7.47-14.88 11.26-22.03 11.26-5.1 0-10.07-1.91-14.77-5.68-3.23-2.59-33.21-31.7-44.46-42.67-2.82-2.82-3.3-5.19-1.44-7.04.94-.94 2.02-1.41 3.22-1.41 1.52 0 3.17.78 4.53 2.15l.04.04 16.61 15.96c.58.56 1.33.84 2.08.84.77 0 1.54-.3 2.13-.88a2.997 2.997 0 0 0 .03-4.2L20.18 45.17c-1.25-1.25-2.01-2.72-2.13-4.14-.12-1.36.35-2.57 1.39-3.61.94-.94 2.02-1.41 3.22-1.41 1.52 0 3.17.78 4.56 2.17l27.15 26.46c.58.57 1.34.85 2.09.85.77 0 1.54-.29 2.12-.88a3.012 3.012 0 0 0 .03-4.22L25.96 26.95c-3.13-3.13-2.35-6.13-.73-7.75.94-.94 2.02-1.41 3.22-1.41 1.52 0 3.17.78 4.56 2.17l33.26 32.43c.58.57 1.34.85 2.09.85.77 0 1.54-.29 2.12-.88a3 3 0 0 0 .03-4.21l-24.2-24.86c-1.25-1.25-2.01-2.72-2.13-4.14-.12-1.36.35-2.57 1.39-3.61.94-.94 2.02-1.42 3.22-1.42m0-3c-1.9 0-3.77.72-5.34 2.29-3.5 3.5-2.78 8.48.73 11.99l24.17 24.83L35.1 17.81c-1.94-1.94-4.32-3.02-6.65-3.02-1.9 0-3.77.72-5.34 2.29-3.5 3.5-2.78 8.48.73 11.99L56.46 62.5 29.31 36.04c-1.94-1.94-4.32-3.02-6.65-3.02-1.9 0-3.77.72-5.34 2.29-3.5 3.5-2.78 8.48.73 11.99l26.56 27.39L28 58.73c-1.94-1.94-4.32-3.02-6.65-3.02-1.9 0-3.77.72-5.34 2.29-3.5 3.5-2.07 7.77 1.44 11.29 0 0 40.65 39.64 44.71 42.89 3.49 2.8 9.22 6.34 16.64 6.34 6.78 0 14.97-2.95 24.15-12.14 24.82-24.82 11.16-40.65 8.39-49.18-2.57-7.94-8.87-18.9-7.86-26.71.8-6.21 1.77-10.43-3.89-13.08a2.8 2.8 0 0 0-1.23-.26c-3.2 0-7.9 4.29-9.81 8.83-1.93 4.58 2.09 13.17 1.72 18.28-.09 1.26-.6 2.65-1.31 2.65-.21 0-.44-.12-.68-.41-.44-.53-32.83-32.35-32.83-32.35-1.94-1.94-4.33-3.03-6.66-3.03z"
      fill="#EDA600"
    />
    <defs>
      <path
        id="handwaveB"
        d="M96.6 19.39c-3.39-.18-8.29 3.03-10.66 8.48-2 4.58 6.53 20.61 6.53 20.61l-7.26-1.78s-31.14 61.54-24.47 66.89 22.98 12.03 42.21-7.21c24.82-24.82 11.16-40.65 8.39-49.18-2.57-7.94-11.42-23.52-8.58-28.97 1.85-3.51-3.3-8.69-6.16-8.84z"
      />
    </defs>
    <clipPath id="handwaveC">
      <use xlinkHref="#handwaveB" />
    </clipPath>
    <g clipPath="url(#handwaveC)">
      <path
        d="M87.88 44.63c-4.77 10.65-2.06 22.47 4.68 34.78.93 1.7 2.52.87 1.71-.88-8.5-18.2-.88-34.64-.88-34.64l-5.51.74z"
        fill="#EDA600"
      />
    </g>
    <path
      d="M15.64 79.89c1.71 4.93 5.22 8.39 10.34 9.63 2.81.68 4.02-3.66 1.2-4.34-3.54-.85-5.99-3.02-7.19-6.48-.95-2.72-5.3-1.55-4.35 1.19z"
      fill="#424242"
    />
    <path
      d="M5.97 80.88c3.53 11.1 13.88 16.68 25.19 14.44 2.84-.56 1.64-4.9-1.2-4.34-8.83 1.75-16.9-2.64-19.66-11.3-.87-2.75-5.21-1.58-4.33 1.2zM68.57 17.37c3.52.62 6.36 2.41 7.78 5.79.47 1.13 1.48 1.91 2.77 1.57 1.06-.28 2.04-1.65 1.57-2.77-2.02-4.82-5.71-8.02-10.92-8.94-2.83-.49-4.05 3.85-1.2 4.35z"
      fill="#424242"
    />
    <path
      d="M67.21 7.39c9.07.98 14.77 8.33 14.75 17.24-.01 2.9 4.49 2.9 4.5 0 .03-11.57-7.75-20.49-19.25-21.74-2.87-.31-2.85 4.19 0 4.5z"
      fill="#424242"
    />
  </svg>
);

export default HandWave;
