import React from 'react';

const Rocket = ({ width, height, size, props }) => (
  <svg
    viewBox="0 0 128 128"
    width={width || size}
    height={height || size}
    {...props}
  >
    <linearGradient
      id="prefix__a"
      x1={82.576}
      x2={114.92}
      y1={97.328}
      y2={97.328}
      gradientTransform="matrix(-1 0 0 1 128.46 0)"
      gradientUnits="userSpaceOnUse"
    >
      <stop stopColor="#FF6D00" offset={0} />
      <stop stopColor="#FF9B00" offset={0.249} />
      <stop stopColor="#FFC600" offset={0.513} />
      <stop stopColor="#FFE000" offset={0.721} />
      <stop stopColor="#FFEA00" offset={0.844} />
    </linearGradient>
    <path
      d="M22.17 90.41c-8.18 8.18-8.62 23.09-8.62 23.09s14.9-.44 23.09-8.62 11.58-18.05 7.58-22.04c-4-4-13.87-.61-22.05 7.57z"
      fill="url(#prefix__a)"
    />
    <path
      d="M39.55 83.16c1.43 0 2.52.37 3.25 1.09 2.75 2.75.31 11.32-7.58 19.22-5.7 5.7-15.19 7.38-19.49 7.86.48-4.3 2.16-13.79 7.85-19.49 5.27-5.28 11.54-8.68 15.97-8.68m0-2c-4.81 0-11.53 3.4-17.38 9.26-8.18 8.18-8.62 23.09-8.62 23.09s14.9-.44 23.09-8.62 11.58-18.05 7.58-22.04c-1.14-1.16-2.75-1.69-4.67-1.69z"
      fill="#424242"
      opacity={0.2}
    />
    <linearGradient
      id="prefix__b"
      x1={93.545}
      x2={110.29}
      y1={91.956}
      y2={109.21}
      gradientTransform="matrix(-1 0 0 1 128.46 0)"
      gradientUnits="userSpaceOnUse"
    >
      <stop stopColor="#C62828" offset={0.004} />
      <stop stopColor="#D75327" offset={0.271} />
      <stop stopColor="#E98126" offset={0.598} />
      <stop stopColor="#F59D25" offset={0.85} />
      <stop stopColor="#F9A825" offset={0.995} />
    </linearGradient>
    <path
      d="M24.65 93.29c-5.15 5.15-5.43 14.53-5.43 14.53s9.38-.28 14.53-5.43 7.29-11.37 4.77-13.88-8.71-.38-13.87 4.78z"
      fill="url(#prefix__b)"
    />
    <path
      d="M35.21 93.38c4.9 5.23 11.33 4.68 11.86 3.82l4.48-7.28c.27-.44.02-1.14-.17-1.32L39.29 76.51c-.19-.19-.86-.41-1.3-.14l-7.28 4.48c-.85.53-1.81 5.78 4.5 12.53z"
      fill="#546E7A"
    />
    <path
      d="M68.13 106.68c-1 .81-2.34.63-2.51-.57-.47-3.32-.16-9.99-4.34-14.17-4.08-4.08-10.71-4.68-10.71-4.68l24.96-12.3s4.7 7.25 2.11 17.57c-2.16 8.53-5.85 11.16-9.51 14.15z"
      fill="#0288D1"
    />
    <path
      d="M74.12 78.99c.18.49.37 1.05.54 1.67.68 2.48 1.25 6.43.06 11.13-1.51 6.02-3.77 8.6-6.42 10.86-.34-3.82-1.08-9.02-4.91-12.84-1.4-1.4-3.04-2.46-4.65-3.25l15.38-7.57m1.4-4.03l-24.96 12.3s6.64.61 10.71 4.68c4.18 4.18 3.87 10.85 4.34 14.17.1.71.62 1.07 1.23 1.07.42 0 .88-.17 1.28-.5 3.66-2.99 7.35-5.62 9.5-14.15 2.61-10.33-2.1-17.57-2.1-17.57z"
      fill="#424242"
      opacity={0.2}
    />
    <path
      d="M20.67 60.65c-.98.84-.63 2.34.57 2.51 3.32.47 9.99.16 14.17 4.34 4.08 4.08 4.93 9.86 4.93 9.86L52.4 51.83s-6.38-2.99-15.66-.58c-6.4 1.67-12.75 6.54-16.07 9.4z"
      fill="#0288D1"
    />
    <path
      d="M43.94 53.31c1.67 0 3.11.18 4.25.4l-7.5 15.9c-.8-1.43-1.83-2.9-3.16-4.22-3.55-3.55-8.27-4.44-11.99-4.82 3.23-2.46 7.62-5.28 11.94-6.41 2.16-.57 4.33-.85 6.46-.85m0-3c-2.11 0-4.54.25-7.2.94-6.4 1.67-12.74 6.54-16.06 9.4-.98.84-.63 2.34.57 2.51 3.32.47 9.99.16 14.17 4.34 4.08 4.08 4.93 9.86 4.93 9.86L52.4 51.83s-3.24-1.52-8.46-1.52z"
      fill="#424242"
      opacity={0.2}
    />
    <path
      d="M38.27 79.73l9.92 9.92-3.13 5.09c-.1.01-.21.01-.33.01-.97 0-4.35-.25-7.33-3.43-3.77-4.03-4.36-7.18-4.31-8.41l5.18-3.18m.21-3.49c-.17 0-.34.04-.49.12l-7.28 4.48c-.86.53-1.82 5.78 4.49 12.53 3.21 3.43 7.08 4.38 9.52 4.38 1.28 0 2.16-.26 2.34-.56l4.48-7.28c.27-.44.02-1.14-.17-1.32L39.29 76.51c-.12-.12-.46-.27-.81-.27z"
      fill="#424242"
      opacity={0.2}
    />
    <radialGradient
      id="prefix__c"
      cx={46.5}
      cy={32.5}
      r={36.473}
      gradientTransform="matrix(-1 0 0 1 128.46 0)"
      gradientUnits="userSpaceOnUse"
    >
      <stop stopColor="#F5F5F5" offset={0.011} />
      <stop stopColor="#BDBDBD" offset={1} />
    </radialGradient>
    <path
      d="M84.07 18.49c4.05 1.86 9.51 5.06 14.72 10.27 1.84 1.84 3.41 3.72 4.77 5.56 2.63 3.55 4.43 6.94 5.61 9.59-2.63 6.91-7.59 14.95-12.44 19.8-19.81 19.81-41.69 31.12-53.01 23.11-.66-.47-1.31-.9-1.9-1.43-9.71-8.73 1.41-33.72 21.61-53.92 5.07-5.07 13.52-10.25 20.64-12.98z"
      fill="url(#prefix__c)"
    />
    <circle cx={78.8} cy={47.18} r={8.9} fill="#455A64" />
    <path
      d="M78.8 40.28c1.84 0 3.58.72 4.88 2.02s2.02 3.04 2.02 4.88-.72 3.58-2.02 4.88-3.04 2.02-4.88 2.02-3.58-.72-4.88-2.02c-2.69-2.69-2.69-7.07 0-9.76 1.3-1.3 3.03-2.02 4.88-2.02m0-2c-2.28 0-4.56.87-6.3 2.61a8.896 8.896 0 0 0 0 12.59c1.74 1.74 4.02 2.61 6.3 2.61s4.56-.87 6.3-2.61a8.896 8.896 0 0 0 0-12.59 8.882 8.882 0 0 0-6.3-2.61z"
      fill="#424242"
      opacity={0.2}
    />
    <circle cx={60.44} cy={66.67} r={6.44} fill="#455A64" />
    <path
      d="M84.07 18.49c-1.85 9.13 14.27 27.01 25.1 25.42 3.37-8.71 4.75-14.62 5.67-24.79.47-5.15-1.04-6.62-5.81-6.06-12.65 1.48-17.65 2.44-24.96 5.43z"
      fill="#E53935"
    />
    <path
      d="M110.74 15.95c.6 0 .9.07 1.01.11.08.18.28.88.11 2.79-.89 9.85-2.17 15.45-5.48 23.99-2.54 6.65-7.38 14.36-11.77 18.75C79.3 76.9 63.22 86.42 52.64 86.42c-2.85 0-5.27-.69-7.19-2.05l-.33-.23c-.51-.35-.94-.66-1.3-.98-1.62-1.46-2.48-3.69-2.56-6.63-.28-10.7 9.7-28.36 24.28-42.94 4.57-4.57 12.63-9.63 19.59-12.29l.06-.03c7.07-2.89 11.99-3.8 24.17-5.23.53-.06.99-.09 1.38-.09m0-3c-.52 0-1.08.04-1.71.11-12.65 1.48-17.65 2.44-24.96 5.43-7.12 2.72-15.57 7.9-20.64 12.97-20.2 20.2-31.32 45.19-21.61 53.92.58.52 1.23.96 1.9 1.43 2.5 1.77 5.52 2.6 8.92 2.6 11.98 0 28.66-10.27 44.1-25.7 4.85-4.85 9.81-12.89 12.44-19.8 3.37-8.71 4.75-14.62 5.67-24.79.39-4.47-.69-6.17-4.11-6.17z"
      fill="#424242"
      opacity={0.2}
    />
    <path
      d="M60.44 62.23c1.19 0 2.3.46 3.14 1.3s1.3 1.95 1.3 3.14-.46 2.3-1.3 3.14-1.95 1.3-3.14 1.3-2.3-.46-3.14-1.3-1.3-1.95-1.3-3.14.46-2.3 1.3-3.14c.85-.84 1.96-1.3 3.14-1.3m0-2c-1.65 0-3.29.63-4.55 1.89a6.44 6.44 0 0 0 0 9.1c1.26 1.26 2.9 1.89 4.55 1.89s3.29-.63 4.55-1.89a6.44 6.44 0 0 0 0-9.1 6.389 6.389 0 0 0-4.55-1.89z"
      fill="#424242"
      opacity={0.2}
    />
  </svg>
);

export default Rocket;
